import React from 'react'
import { Link } from "gatsby"

function CaseStudyFeatures() {
  return (
     <>
        <h2>Other case study links</h2>
        <ul>
            <li><Link to="/matthews-specialty-vehicles-greensboro-nc">Matthews Specialty Vehicles – Greensboro, NC</Link></li>
            <li><Link to="/mchp-medical-clinics-bakersville-nc">MCHP Medical Clinics – Bakersville, NC</Link></li>
            <li><Link to="/city-transfer-and-storage-high-point-nc">City Transfer and Storage – High Point, NC</Link></li>
            <li><Link to="/halifax-county-health-department-halifax-nc">Halifax County Health Department – Halifax, NC</Link></li>
            <li><Link to="/perquimans-county-schools-hertford-nc">Perquimans County Schools – Hertford, NC</Link></li>
        </ul>
    </>
  )
}

export default CaseStudyFeatures