import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, Tags, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner } from '../components/Section'
import { LetastArticlesBgWhite, CustomTags, SectionBeforefterBg, PageTitle, PageTitleWrapper, CardImg, TraingleShape, CaseStudsyContent, TagWrapper, CaseStudyWrapper} from "../components/CaseStudyStyle"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LetastArticles from "../components/LetastArticles"
import BgBefore from '../images/case-study-before.png'
import BgAfter from '../images/case-study-after.png'
import CaseStudyFeatures from "../components/CaseStudyFeatures"


function caseStudyPage() {
  return (
    <Layout pageName="case-study-detail">
        <SEO title="Perquimans County Schools – Hertford, NC" description="Perquimans County Schools – Hertford, NC" />
        <SectionBeforefterBg pt="156px" pb="100px" xpt="80px" xpb="60px" mpb="40px" bgColor="#F8F8F8" bgBefore={`url(${BgBefore})`} bgAfter={`url(${BgAfter})`}>
        <TraingleShape />
        <div className="container">
            <BreadCrumb>
                <Link to="/">Home</Link> <Link to="/case-studies">Case Studies</Link><Link to="/#">Perquimans County Schools – Hertford, NC</Link>
            </BreadCrumb>
            <PageTitleWrapper>
                <PageTitle className="h2">Perquimans County Schools – Hertford, NC</PageTitle>
                <Tags to="/">Video Surveillance System Installation</Tags>
            </PageTitleWrapper>
            <CaseStudyWrapper>
                <CaseStudsyContent>
                    <CardImg>
                        <StaticImage
                        src="../images/case.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                        />
                    </CardImg>
                    <p>The legacy camera & surveillance system being used by Perquimans County Schools had aged out and was no longer supported. The client came to WYN Technologies in need of a video surveillance solution which would provide effective monitoring of school properties, both inside and outside.</p>
                </CaseStudsyContent>
            </CaseStudyWrapper>
        </div>
        </SectionBeforefterBg>
        <Section pt="0" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
            <div className="container">
                <CaseStudyWrapper>
                    <MarkdownContentWrapper>
                    <MarkdownContentWrapperInner>
                        <MarkdownContent>
                            <h2>Services Delivered</h2>
                            <TagWrapper>
                                <CustomTags>Camera Surveillance System installation & configuration</CustomTags>
                            </TagWrapper>
                            <p>WYN conducted a site survey, and provided a written assessment of our findings to the school system. We studied potential problem areas, lighting challenges, camera angles, and high traffic areas. A new surveillance system was installed to meet the client’s budget, including a 5-year warranty on parts and workmanship.</p>
                            <ul>
                                <li>Provided a real-time application to view cameras through either PC or smartphone.</li>
                                <li>Cameras were mounted with proper angling for optimal video surveillance.</li>
                                <li>Industrial hardware was used for exterior camera mounting to provide better longevity through extreme weather events.</li>
                                <li>Interior cameras were locked into place to prevent any accidental movement or tampering.</li>
                                <li>The network video recorder was upgraded with additional storage to provide for 90-day playback.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Problem Statements</h2>
                            <p>The client’s legacy surveillance system was outdated and no longer supported. Existing camera views were not optimal due to remodeling and construction, which had taken place since the original camera installation. Other areas of concern didn’t have optimal camera coverage, either. All cabling also needed to be updated in order to provide needed bandwidth.</p>
                            <ul>
                                <li>Parking lot areas</li>
                                <li>Student drop off points</li>
                                <li>Staff entry and exit areas</li>
                                <li>Internal hallways</li>
                                <li>Key inside rooms and gathering spaces including weight room, cafeteria, library, gym, and doorways</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Wyn Approach</h2>
                            <p>The WYN approach to serving the client’s needs began with conducting a thorough site survey. We assessed current surveillance coverage, and flagged areas of improvement for all aforementioned areas. Our goal was to address the issues which the client identified as being most important, and included the installation of updated cabling for each camera location.</p>
                            <ul>
                                <li>The first point of the order was updating the cabling. We were also able to provide one demarcation point without the need for multiple network video recorders, resulting in cost savings for the client.</li>
                                <li>The second point of the order was to install weatherproof outside wall penetrations for the cable connections.</li>
                                <li>Third, we installed proper cameras for each respective area, using either pan tilt zoom technology or zoom-only cameras.</li>
                                <li>We determined that low-light areas required night vision cameras in order to provide needed surveillance coverage for dark conditions.</li>
                                <li>The final step was walking the site with the client and showing them each remote camera view, so the client could sign off on final camera placement.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Challenges</h2>
                            <p>The WYN team was faced with a few unique camera placements and installation challenges. Some areas had low light conditions, while others posed issues due to daytime sun glare, door swing visibility, etc. Finding proper cabling paths was challenging in some areas, too. Installation was required to be conducted at night to avoid any disruption to students or staff.</p>
                            <ul>
                                <li><strong>Challenge 1:</strong> In some cases, existing cable paths could be used for cable routing. However, this wasn’t possible in other areas due to building additions and the presence of fluorescent light ballasts (potential for interference).</li>
                                <li><strong>Challenge 2:</strong> Finding the best surveillance approach for high-traffic areas with low-light conditions.</li>
                                <li><strong>Challenge 3:</strong> Working around sunspots and daytime glare in entry door areas.</li>
                                <li><strong>Challenge 4:</strong> The ceiling composition posed installation challenges in some locations.</li>
                                <li><strong>Challenge 5:</strong> Finding the right spot/angle for camera mounting for optimal views based on door swing.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Benefits</h2>
                            <p>The up-to-date surveillance technology provided by WYN offers many benefits. High-traffic areas and other areas which were hard to monitor in the past are now much easier to monitor, and the whole system can be managed remotely. The end result is additional safety and security for students, staff, and visitors in the Perquimans County school system.</p>
                            <ul>
                                <li>Helped to reduce the need for in-person staff monitoring of various locations</li>
                                <li>Able to detect problems or areas that need to be addressed in real-time</li>
                                <li>In the event of a weather threat, staff can assess the condition of outside areas and determine whether a safe passage is available</li>
                                <li>The surveillance system keeps a record of any events that may require review by school safety officers, staff, or parents</li>
                                <li>Modern surveillance technology gives parents and staff greater peace of mind about the level of security being provided</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <CaseStudyFeatures />
                        </MarkdownContent>
                    </MarkdownContentWrapperInner>
                    </MarkdownContentWrapper>
                </CaseStudyWrapper>
            </div>
        </Section>
        <LetastArticlesBgWhite>
         <LetastArticles />
        </LetastArticlesBgWhite>
    </Layout>
  )
}
export default caseStudyPage