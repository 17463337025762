import styled from "styled-components";
import { Section, Tags } from '../Section'
import BreakpointDown from "../Media/BreakpointDown";
import BreakpointUp from "../Media/BreakpointUp";

export const LetastArticlesBgWhite = styled.div`
    section{
        background:#fff;
    }
`
export const CustomTags = styled(Tags)`
    ${BreakpointUp.md`
        padding:10px 20px;
        background:#fff;
        font-size:18px;
        font-weight:bold;
    `}
    & + & {
        margin-left:20px;
    }
    &:hover{
        background:#529A00;
        color:#fff;
    }
`

export const SectionBeforefterBg = styled(Section)`
    z-index:2; 
    .container{
        z-index:2;
    }
    &:before{
        background-position:top left;
        ${BreakpointDown.xl`
            background-size: 22%; 
        `}
            ${BreakpointDown.lg`
            background-size: 22%;
        `}
    }
    &:after{
        background-position: top  right;
        ${BreakpointDown.xl`
            background-size: 25%;
        `}
        ${BreakpointDown.lg`
            background-size: 20%;
        `}
    }
`
export const PageTitle = styled.h1`
  margin-bottom:10px;
  text-transform: capitalize;
`
export const PageTitleWrapper = styled.div`
    text-align:center;
    margin-top:50px;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
    ${BreakpointDown.lg`
        margin-top:20px;
    `}
    ${BreakpointDown.md`
        text-align:left;
    `}
`

export const CardImg = styled.div`
    position: relative;
    max-width:100%;
    width:100%;
    margin-top:26px;
    margin-left:auto;
    margin-right:auto;
    .gatsby-image-wrapper{
        width:100%;
    }
`

export const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3; 
`
export const CaseStudsyContent = styled.div`
    margin-top:40px;
    ${BreakpointDown.lg`
        margin-top:20px;
    `}
    p{
        margin-top:40px;
        ${BreakpointDown.lg`
            margin-top:20px;
        `}
    }
`

export const TagWrapper = styled.div`
    margin-bottom:20px;
    ${BreakpointDown.lg`
        margin-top:0px;
        margin-bottom:10px;
    `}
`
export const CaseStudyWrapper = styled.div`
    max-width:1400px;
    margin-left:auto;
    margin-right:auto;
    div[class*="MarkdownContent-"]{
        &:first-of-type{
        border-top:2px solid #ccc;
        }
    }
`